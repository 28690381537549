import { render, staticRenderFns } from "./TabApps.vue?vue&type=template&id=9a81194e&scoped=true"
import script from "./TabApps.vue?vue&type=script&lang=js"
export * from "./TabApps.vue?vue&type=script&lang=js"
import style0 from "./TabApps.vue?vue&type=style&index=0&id=9a81194e&prod&lang=css"
import style1 from "./TabApps.vue?vue&type=style&index=1&id=9a81194e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a81194e",
  null
  
)

export default component.exports