<template>
    <div>
        <v-card-title style="align-items: center;">
            <ApplicationEditDialog @save="getApplicationList()">
                <v-btn class="mr-2" color="info" depressed>
                    <v-icon>mdi-plus</v-icon>App
                </v-btn>
            </ApplicationEditDialog>

            <v-spacer />

            <v-row>
                <v-col cols="9">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search apps"
                        single-line
                        hide-details
                        clearable
                    />
                </v-col>
                <v-col cols="3">
                    <v-select
                        label="Status"
                        :items="filterStatusItems"
                        item-text="text"
                        item-value="value"
                        v-model="filterStatus"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="getApplicationsByFilters()"
                :expanded.sync="expanded"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[true]"
                :item-class="rowClasses"
                :loading="loading"
                hide-default-footer
                disable-pagination
            >
                <template v-slot:item.name="{ item }">
                    <div class="app-name">
                        <div class="app-name__line1">{{ item.name }}</div>
                        <div class="app-name__line2">
                            {{ getVendorName(item.vendor) }}
                            <v-chip
                                v-if="item.vendorList.length > 1"
                                x-small
                                style="margin-top: -2px;"
                                >{{ item.vendorList.length }}</v-chip
                            >
                        </div>
                    </div>
                </template>

                <template v-slot:item.vendor.proxy_status="{ item }">
                    <div class="pl-2">
                        <ApplicationProxyStatus
                            :status="item.vendor.proxy_status || null"
                        />
                    </div>
                </template>

                <template
                    v-slot:item.vendor.last_sync_summary_sales_job="{ item }"
                >
                    <ApplicationReportStatus
                        :application="{}"
                        :latestReport="item.vendor.last_sync_summary_sales_job"
                        :enabled="true"
                    />
                </template>

                <template
                    v-slot:item.vendor.last_sync_subscription_event_job="{
                        item
                    }"
                >
                    <ApplicationReportStatus
                        :application="{}"
                        :latestReport="
                            item.vendor.last_sync_subscription_event_job
                        "
                        :enabled="true"
                    />
                </template>

                <template
                    v-slot:item.vendor.last_sync_subscriber_job="{ item }"
                >
                    <ApplicationReportStatus
                        :application="{}"
                        :latestReport="item.vendor.last_sync_subscriber_job"
                        :enabled="true"
                    />
                </template>

                <template v-slot:item.last_backend_events_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_backend_events_sync_job"
                        :enabled="item.enabled && item.parsing_events_from_app"
                    />
                </template>

                <template v-slot:item.last_backend_reviews_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_backend_reviews_sync_job"
                        :enabled="item.enabled && item.is_collect_app_reviews"
                    />
                </template>

                <template v-slot:item.last_backend_refunds_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_backend_refunds_sync_job"
                        :enabled="item.enabled && item.is_refund_parsing"
                    />
                </template>

                <template v-slot:item.last_backend_unsubs_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_backend_unsubs_sync_job"
                        :enabled="
                            item.enabled && item.parse_backend_unsubscribes
                        "
                    />
                </template>

                <template v-slot:item.last_backend_pp_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_backend_pp_sync_job"
                        :enabled="item.enabled && item.parse_backend_pp"
                    />
                </template>

                <!-- <template v-slot:item.last_itunes_reviews_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_itunes_reviews_sync_job"
                        :enabled="item.enabled && item.parse_reviews"
                    />
                </template> -->

                <template v-slot:item.last_reviews_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_reviews_sync_job"
                        :enabled="item.enabled && item.is_collect_app_reviews"
                    />
                </template>

                <template v-slot:item.last_mailbox_sync_job="{ item }">
                    <ApplicationReportStatus
                        :application="item"
                        :latestReport="item.last_mailbox_sync_job"
                        :enabled="item.enabled && item.parse_mailbox"
                    />
                </template>

                <template v-slot:item.actions="{ item }">
                    <ApplicationEditDialog
                        :applicationId="item.id"
                        @save="getApplicationList()"
                    >
                        <v-btn class="ml-2" color="info" fab x-small depressed>
                            <v-icon aria-hidden="false">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </ApplicationEditDialog>

                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ml-2"
                                fab
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                depressed
                            >
                                <v-icon aria-hidden="false">
                                    mdi-dots-horizontal
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-hover v-slot="{ hover }" open-delay="0">
                                <v-list-item
                                    :class="{ 'menu-hover': hover }"
                                    disabled
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-sync</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Sync AppStore
                                            Data</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-hover>

                            <v-hover v-slot="{ hover }" open-delay="0">
                                <v-list-item
                                    :class="{ 'menu-hover': hover }"
                                    :href="getXLSDownloadLink(item)"
                                    :disabled="
                                        false /*canGetXLSDownloadLink(item) === false*/
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-download</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Get Data XLS</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-hover>

                            <ApplicationRemoteBackendEditDialog
                                :application="item"
                            >
                                <v-hover v-slot="{ hover }" open-delay="0">
                                    <v-list-item
                                        :class="{ 'menu-hover': hover }"
                                        disabled
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-remote</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                >Backend
                                                Settings</v-list-item-title
                                            >
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-hover>
                            </ApplicationRemoteBackendEditDialog>

                            <v-hover v-slot="{ hover }" open-delay="0">
                                <v-list-item
                                    :class="{ 'menu-hover': hover }"
                                    @click="remove(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="red"
                                            >mdi-trash-can</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="red--text"
                                            >Remove
                                            Application</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-hover>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card-text>
    </div>
</template>

<style>
tr.app-disabled td {
    opacity: 0.4;
}

tr.app-disabled td:last-child {
    opacity: 1;
}
</style>

<style scoped>
.app-name__line1 {
    font-size: 14px;
    line-height: 20px;
}

.app-name__line2 {
    font-size: 12px;
    line-height: 12px;
    color: #999;
}

.menu-hover {
    background-color: #f2f2f2;
    cursor: pointer;
}
</style>

<script>
import { converValidationServerErrorsToString } from "../../../../utils/errorUtil";
import { eventBus, eventBusEvents } from "../../../../eventBus/eventBus";
import ApplicationEditDialog from "../../../../components/Application/ApplicationEditDialog";
import ApplicationReportStatus from "../../../../components/Application/ApplicationReportStatus";
import ApplicationProxyStatus from "../../../../components/Application/ApplicationProxyStatus";

import ApplicationRemoteBackendEditDialog from "../../../../components/Application/ApplicationRemoteBackendEditDialog.vue";

const queryString = require("query-string");

export default {
    name: "TabApps",
    mounted() {
        this.getApplicationList();
    },
    components: {
        ApplicationEditDialog,
        ApplicationReportStatus,
        ApplicationProxyStatus,

        ApplicationRemoteBackendEditDialog
    },
    data() {
        return {
            applications: [],
            expanded: [],
            singleExpand: false,
            search: "",
            filterStatusItems: [],
            filterStatus: "enabled",
            queryParams: {},
            loading: false,
            headers: [
                {
                    text: "ID",
                    align: "start",
                    sortable: true,
                    value: "id"
                },
                {
                    text: "App / Account",
                    align: "start",
                    sortable: true,
                    value: "name"
                },
                {
                    text: "Proxy",
                    align: "start",
                    sortable: true,
                    value: "vendor.proxy_status"
                },
                {
                    text: "ASC Sales",
                    align: "start",
                    sortable: false,
                    value: "vendor.last_sync_summary_sales_job"
                },
                {
                    text: "ASC Events",
                    align: "start",
                    sortable: false,
                    value: "vendor.last_sync_subscription_event_job"
                },
                {
                    text: "ASC Subs",
                    align: "start",
                    sortable: false,
                    value: "vendor.last_sync_subscriber_job"
                },
                {
                    text: "Backend Events",
                    align: "start",
                    sortable: false,
                    value: "last_backend_events_sync_job"
                },
                {
                    text: "Backend Reviews",
                    align: "start",
                    sortable: false,
                    value: "last_backend_reviews_sync_job"
                },
                {
                    text: "Backend Refunds",
                    align: "start",
                    sortable: false,
                    value: "last_backend_refunds_sync_job"
                },
                {
                    text: "Backend Unsubs",
                    align: "start",
                    sortable: false,
                    value: "last_backend_unsubs_sync_job"
                },
                {
                    text: "Backend Pp",
                    align: "start",
                    sortable: false,
                    value: "last_backend_pp_sync_job"
                },
                {
                    text: "Reviews",
                    align: "start",
                    sortable: false,
                    value: "last_reviews_sync_job"
                },
                {
                    text: "Mails",
                    align: "start",
                    sortable: false,
                    value: "last_mailbox_sync_job"
                },
                {
                    text: "",
                    sortable: false,
                    value: "actions",
                    width: "10%"
                }
            ],
            tab: null
        };
    },
    created() {},
    methods: {
        getApplicationList() {
            let q = queryString.stringify(this.queryParams, {
                arrayFormat: "bracket",
                skipNull: true,
                skipEmptyString: true
            });

            this.loading = true;

            this.$http
                .get(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?${q}`
                )
                .then(response => {
                    this.loading = false;
                    this.applications = response.data.data.items;

                    this.updateFilterStatusItems();
                })
                .catch(err => {
                    this.loading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },
        updateFilterStatusItems() {
            let countEnabled = 0;
            let countDisabled = 0;

            for (let i in this.applications) {
                const app = this.applications[i];
                if (app.enabled == 1) {
                    countEnabled++;
                }
            }

            for (let i in this.applications) {
                const app = this.applications[i];
                if (app.enabled == 0) {
                    countDisabled++;
                }
            }

            this.filterStatusItems = [
                { text: `Enabled (${countEnabled})`, value: "enabled" },
                { text: `Disabled (${countDisabled})`, value: "disabled" }
            ];
        },
        getApplicationsByFilters() {
            if (this.filterStatus === "enabled") {
                return this.applications.filter(item => {
                    return item.enabled == 1;
                });
            }

            if (this.filterStatus === "disabled") {
                return this.applications.filter(item => {
                    return item.enabled == 0;
                });
            }

            return this.applications;
        },
        remove(application) {
            if (!confirm("Remove application?")) {
                return;
            }
            this.$http
                .delete(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${application.id}`
                )
                .then(response => {
                    console.log("application removed", response.data);
                    this.getApplicationList();
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getXLSDownloadLink(application) {
            return `${process.env.VUE_APP_SERVER_BASE_URL}/applications/${application.id}/summary-statistics/download`;
        },
        canGetXLSDownloadLink(application) {
            return this.isReportCompleted(application);
        },
        isReportCompleted(application) {
            return true;
        },
        rowClasses(item) {
            // if (!item.enabled) {
            //     return 'app-disabled';
            // }
            return "";
        },

        getVendorName(vendor) {
            if (!vendor) {
                return "No Account";
            }
            if (vendor.name) {
                return `${vendor.name} (id=${vendor.id})`;
            }
            return `Vendor #${vendor.number} (id=${vendor.id})`;
        }
    }
};
</script>
